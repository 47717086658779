<template>
    <div class="Targecustomer">
        <div class="rana_con">
            <div class="rana_con_title">
                {{form.title}}
            </div>
            <div class="stomerDiv clearfix">
                <div class="sto_left fl">
                    <div class="sto_l_top">
                        <p class="sto_l_p">购买场景</p>
                        <div class="clearfix" style="text-align:center">
                            <div class="icon_left fl">
                                <div class="icon_left_top">
                                    <i class="iconfonts icons-kehu"></i>
                                </div>
                                <p class="icon_pp">目标客户</p>
                            </div>
                            <i class="el-icon-plus" style="font-size:45px;color:#fff;line-height:70px"></i>
                            <div class="icon_left fr">
                                <div class="icon_left_top">
                                    <i class="iconfonts icons-Bag" style="font-size:45px"></i>
                                </div>
                                <p class="icon_pp">购买动机</p>
                            </div>
                        </div>
                    </div>
                    <img class="arr4img" src="./../../../assets/img/arrow_4.png" alt="">
                    <div class="icon_left">
                        <div class="icon_left_top">
                            <i class="iconfonts icons-gouwucheman" style="font-size:40px"></i>
                        </div>
                        <p class="icon_pp">购买行为</p>
                    </div>
                </div>
                <div class="sto_right fl">
                    <ul class="sto_right_ul">
                        <li class="sto_right_li" v-for="(item,index) in arrList" :key="index">
                            <div class="sto_right_li_div">
                                <i class="iconfonts icons-tubiao_changjing"></i>
                                <span>{{item.title}}</span>
                            </div>
                            <div class="sto_div_span" :title="item.value.gmcj">
                                <span>{{item.value.gmcj}}</span>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <!-- 按钮 -->
            <div class="S_butt_div">
                <div class="S_button_one" v-preventReClick @click="nextBotn()">
                    <span>返回首页</span>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            arrList:[],
            textarea:'',
            form:{
                p1:{
                    value:{
                        gmcj:''
                    }
                },
                p2:{
                    value:{
                        gmcj:''
                    }
                },
                p3:{
                    value:{
                        gmcj:''
                    }
                },
                p4:{
                    value:{
                        gmcj:''
                    }
                },
                p5:{
                    value:{
                        gmcj:''
                    }
                },
                p6:{
                    value:{
                        gmcj:''
                    }
                },
                title:''
            }
        }
    },
    methods: {
        getapp(){
            this.axios.projectS4P1({
                token:this.$store.state.token,
                id:this.$store.state.cla_id,
            }).then(res=>{
                if(res.data.code==0){
                    var str = res.data.data.qrcode
                    this.$emit('info', str);
                    this.form = res.data.data.data
                    var arr = []
                    if(this.form.step>5){
                        arr.push(this.form.p1)
                        arr.push(this.form.p2)
                        arr.push(this.form.p3)
                        arr.push(this.form.p4)
                        arr.push(this.form.p5)
                        arr.push(this.form.p6)
                    }else if(this.form.step>4){
                        arr.push(this.form.p1)
                        arr.push(this.form.p2)
                        arr.push(this.form.p3)
                        arr.push(this.form.p4)
                        arr.push(this.form.p5)
                    }else if(this.form.step>3){
                        arr.push(this.form.p1)
                        arr.push(this.form.p2)
                        arr.push(this.form.p3)
                        arr.push(this.form.p4)
                    }else if(this.form.step>2){
                        arr.push(this.form.p1)
                        arr.push(this.form.p2)
                        arr.push(this.form.p3)
                    }
                    this.arrList = arr
                }else{
                    this.$message.error(res.data.message)
                }
            }).catch(err=>{

            })
        },
        nextBotn(){
            this.$router.push({name:'StudentPage'})
        },
        prevstep(){
            this.$router.push({name:'StudentPage'})
        }
    },
    mounted() {
        this.getapp()
    }
}
</script>
<style scoped>
@import './../../../assets/css/student/A/Targecustomer.css';
</style>
<style>
.sto_right_li .el-textarea__inner{
    widows: 100%;
    height: 66px;
    resize: none;
    background: #1C2779;
    border: 1px solid #141C54;
    border-radius: 2px;
    box-sizing: border-box;
    color: #ECEBEB;
    font-size: 14px;
    padding: 5px 10px;
    font-family: 'Open Sans', Helvetica, Arial, sans-serif;
}
</style>